import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["type", "values"]

  toggleEdit(event) {
    this.element
      .querySelector(".rule")
      .classList.toggle("edit")
    event.preventDefault()
  }

  delete(event) {
    this.element.remove()
    event.preventDefault()
  }

  refresh(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.element.outerHTML = response.rule
  }

  changedType(event) {
    fetch(
      event.target.getAttribute("data-url") +
        "?type=" +
        event.target.value,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then((response) => {
        if (
          response.redirected &&
          response.url.match("sign_in")
        ) {
          location.reload()
        } else {
          return response.json()
        }
      })
      .then((response) => {
        this.valuesTarget.innerHTML = response.values
      })
  }
}
