import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleEdit() {
    this.element.classList.toggle("edit")
    event.preventDefault()
  }

  weekdaySwitch(event) {
    event.target
      .closest(".template-weekday")
      .classList.toggle("disabled")
  }

  refresh(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.element.outerHTML = response.rule
  }
}
