import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs/Sortable.js"

export default class extends Controller {
  connect() {
    // Element
    const container = this.element

    // Get CSRF tokenn
    const csrf_token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content")

    // Init sortable
    new Sortable(container, {
      animation: 150,
      handle: ".handle",
      scroll: true,
      scrollSensitivity: 100,
      scrollSpeed: 10,
      onSort: function (e) {
        // Only proceed if url is set end AJAX request to server
        if (!container.getAttribute("data-sortable-url"))
          return

        // Build URL
        let url = new URL(
          container.getAttribute("data-sortable-url")
        )
        url.searchParams.append(
          "id",
          e.item.getAttribute("data-id")
        )
        url.searchParams.append(
          "from_parent_id",
          e.from.getAttribute("data-id")
        )
        url.searchParams.append(
          "to_parent_id",
          e.to.getAttribute("data-id")
        )
        url.searchParams.append("position", e.newIndex)

        fetch(url, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf_token
          }
        })
      }
    })
  }
}
