// Import Flatpickr and language
import Flatpickr from "stimulus-flatpickr"
import { German } from "flatpickr/dist/l10n/de.js"

export default class extends Flatpickr {
  initialize() {
    // Get picker properties
    const enableTime = this.element.getAttribute(
      "data-flatpickr-enable-time"
    )
    const noCalendar = this.element.getAttribute(
      "data-flatpickr-no-calendar"
    )
    const vacationDates = this.element.getAttribute(
      "data-flatpickr-vacation-days"
    )
    const timeCompensationDates = this.element.getAttribute(
      "data-flatpickr-time-compensation-days"
    )
    const freeTimeDates = this.element.getAttribute(
      "data-flatpickr-free-time-days"
    )
    const shiftDates = this.element.getAttribute(
      "data-flatpickr-shift-days"
    )

    // Choose the right date format
    let dateFormat = "d.m.Y"
    if (enableTime != null && noCalendar != null)
      dateFormat = "H:i"
    else if (enableTime != null) dateFormat = "d.m.Y H:i"

    let self = this

    // Set config
    this.config = {
      locale: German,
      allowInput: true,
      dateFormat: dateFormat,
      onChange: function (
        selectedDates,
        dateStr,
        instance
      ) {
        const changeFunction = this.element.getAttribute(
          "data-flatpickr-change-function"
        )
        if (
          changeFunction &&
          changeFunction === "toggleFixedLengthSubtitle"
        ) {
          self.toggleFixedLengthSubtitle()
        }
      },
      onDayCreate: (dObj, dStr, fp, dayElem) => {
        const date = dayElem.dateObj
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-")

        if (
          shiftDates &&
          JSON.parse(shiftDates).indexOf(date) !== -1
        ) {
          dayElem.classList.add("calendar-shift")
        } else if (
          vacationDates &&
          JSON.parse(vacationDates).indexOf(date) !== -1
        ) {
          dayElem.classList.add("calendar-vacation-request")
        } else if (
          timeCompensationDates &&
          JSON.parse(timeCompensationDates).indexOf(
            date
          ) !== -1
        ) {
          dayElem.classList.add(
            "calendar-time-compensation-request"
          )
        } else if (
          freeTimeDates &&
          JSON.parse(freeTimeDates).indexOf(date) !== -1
        ) {
          dayElem.classList.add(
            "calendar-free-time-request"
          )
        }
      }
    }

    // Set allowInput: true, and forbid keypress is a hack to allow HTML
    // validations. https://github.com/flatpickr/flatpickr/issues/892
    this.element.onkeypress = () => false
    this.element.setAttribute("autocomplete", "off")
  }

  timeStringToMinutes(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString
      .split(":")
      .map(Number)
    // Convert the hours and minutes into total minutes
    return hours * 60 + minutes
  }

  subtractTimeStrings(fromTime, toTime) {
    // Convert time strings to minutes
    const fromMinutes = this.timeStringToMinutes(fromTime)
    const toMinutes = this.timeStringToMinutes(toTime)
    // Subtract minutes
    const differenceMinutes = toMinutes - fromMinutes
    // Convert the difference back to time string
    return differenceMinutes
  }

  toggleFixedLengthSubtitle() {
    const startAtElement = Array.from(
      this.element
        .closest(".modal-body")
        .querySelectorAll(
          "input[name='shift[start_at]'][data-controller='flatpickr']"
        )
    ).pop()
    const endAtElement = Array.from(
      this.element
        .closest(".modal-body")
        .querySelectorAll(
          "input[name='shift[end_at]'][data-controller='flatpickr']"
        )
    ).pop()
    const differenceMinutes = this.subtractTimeStrings(
      startAtElement.value,
      endAtElement.value
    )
    const titleElement = Array.from(
      document.querySelectorAll("#fixed-length-subtitle")
    ).pop()
    const fixedLength = titleElement.dataset.fixedLength

    if (fixedLength == differenceMinutes) {
      titleElement.classList.remove("d-none")
    } else {
      titleElement.classList.add("d-none")
    }
  }
}
