import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["userswitch"]

  // Modals might get varibles from the outisde
  // This replaces all form or input elements inside the modal with variables
  // Variables are provided as json encoded attribute on the opening element
  connect() {
    const modals = document.querySelectorAll(".modal")
    modals.forEach(function (modal) {
      modal.addEventListener(
        "shown.bs.modal",
        function (event) {
          if (
            event.relatedTarget == null ||
            event.relatedTarget.dataset.variables == null
          )
            return

          const variables = JSON.parse(
            event.relatedTarget.dataset.variables
          )
          // Iterate all placeholders
          Object.keys(variables).forEach(function (key) {
            let elements = event.target.querySelectorAll(
              ".placeholder-" + key
            )
            // Replace placeholder depending on type of element
            elements.forEach((element) => {
              const replaceAttr = (attr) => {
                if (new RegExp(`%{${key}}`).test(attr)) {
                  element.setAttribute(
                    "href",
                    attr.replaceAll(
                      `%{${key}}`,
                      encodeURI(variables[key])
                    )
                  )
                }
              }
              if (element.tagName === "A") {
                let attributeText
                // Handle both uriencoded and unencoded placeholders
                attributeText = element.getAttribute("href")
                if (
                  new RegExp(`%25%7B${key}%7D`).test(
                    attributeText
                  )
                ) {
                  replaceAttr(decodeURI(attributeText))
                } else {
                  replaceAttr(attributeText)
                }
              } else if (element.tagName === "FORM")
                element.setAttribute(
                  "action",
                  variables[key]
                )
              else if (element.tagName === "INPUT")
                element.innerHTML = variables[key]
              else element.textContent = variables[key]

              // For datepickers, refresh date
              if (
                element.classList.contains(
                  "flatpickr-input"
                )
              ) {
                element._flatpickr.setDate(
                  element.value,
                  true
                )
              }
            })
          })
        }
      )
    })
  }
}
