import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "normalTypeSelect",
    "guidedTourTypeSelect",
    "workspaceSelect",
    "responsibleForDay",
    "responsibleForDayWarning",
    "responsibleChangedToAbsenceWarning",
    "shiftTitle",
    "start",
    "end"
  ]

  connect() {
    // Init workspace select
    if (
      this.hasNormalTypeSelectTarget &&
      this.hasWorkspaceSelectTarget &&
      this.normalTypeSelectTarget.value === "regular"
    ) {
      this.showWorkSpaceSelect()
      this.enableResponsibleForDay()
    } else {
      this.disableResponsibleForDay()
    }

    if (
      this.hasResponsibleForDayWarningTarget &&
      this.responsibleForDayTarget.checked &&
      this.responsibleForDayTarget.getAttribute(
        "data-others-are-responsible"
      ) === "true"
    ) {
      this.responsibleDayWarningTarget.classList.remove(
        "d-none"
      )
      this.responsibleDayWarningTarget.classList.add(
        "d-block"
      )
    }

    // Init shift type select
    const select =
      this.workspaceSelectTarget.querySelector("select")
    if (
      select.options[select.selectedIndex].text ===
      "Vermittlung"
    ) {
      this.showGuideShiftTypes()
    } else {
      this.showNormalShiftTypes()
    }
  }

  toggleResponsibleChangedToAbsenceWarning(event) {
    if (
      event.target.value !== "regular" &&
      event.target.value !== "extraordinary" &&
      this.hasResponsibleChangedToAbsenceWarningTarget &&
      this.responsibleForDayTarget.checked
    ) {
      this.responsibleChangedToAbsenceWarningTarget.classList.remove(
        "d-none"
      )
      this.responsibleChangedToAbsenceWarningTarget.classList.add(
        "d-block"
      )
    }
  }

  showWorkSpaceSelect() {
    this.workspaceSelectTarget.classList.remove("d-none")
    this.workspaceSelectTarget.classList.add("d-block")
  }

  hideWorkSpaceSelect() {
    this.workspaceSelectTarget.classList.remove("d-block")
    this.workspaceSelectTarget.classList.add("d-none")
  }

  toggleWorkspaceSelect(event) {
    if (!this.hasWorkspaceSelectTarget) {
      return
    }

    if (event.target.value === "regular") {
      this.showWorkSpaceSelect()
    } else {
      this.hideWorkSpaceSelect()
    }
  }

  enableResponsibleForDay() {
    this.responsibleForDayTarget.removeAttribute("disabled")
    this.responsibleForDayTarget.parentNode.classList.remove(
      "text-muted"
    )
  }

  disableResponsibleForDay() {
    this.responsibleForDayTarget.setAttribute(
      "disabled",
      "disabled"
    )
    this.responsibleForDayTarget.parentNode.classList.add(
      "text-muted"
    )
  }

  toggleResponsibleForDay(event) {
    if (!this.hasResponsibleForDayTarget) {
      return
    }

    if (event.target.value === "regular") {
      this.enableResponsibleForDay()
    } else {
      this.disableResponsibleForDay()
    }
  }

  changeResponsibleForDay(event) {
    if (
      event.target.checked &&
      event.target.getAttribute(
        "data-others-are-responsible"
      ) === "true"
    ) {
      this.responsibleForDayWarningTarget.classList.remove(
        "d-none"
      )
      this.responsibleForDayWarningTarget.classList.add(
        "d-block"
      )
    }
  }

  toggleShiftTitle(event) {
    if (!this.hasShiftTitleTarget) {
      return
    }

    if (event.target.value === "extraordinary") {
      this.shiftTitleTarget.classList.toggle(
        "d-none",
        event.target.value !== "extraordinary"
      )
    }
  }

  changeShiftTimes(event) {
    const dataset =
      event.target.options[event.target.selectedIndex]
        .dataset
    this.startTarget._flatpickr.setDate(
      dataset.startAt,
      true
    )
    this.endTarget._flatpickr.setDate(dataset.endAt, true)
  }

  changeShiftTypes(event) {
    if (
      event.target.options[event.target.selectedIndex]
        .text === "Vermittlung"
    ) {
      this.showGuideShiftTypes()
    } else {
      this.showNormalShiftTypes()
    }
  }

  showNormalShiftTypes() {
    this.normalTypeSelectTarget.classList.remove("d-none")
    this.normalTypeSelectTarget.setAttribute(
      "name",
      "shift[type]"
    )
    this.guidedTourTypeSelectTarget.classList.add("d-none")
    this.guidedTourTypeSelectTarget.removeAttribute("name")
  }

  showGuideShiftTypes() {
    this.normalTypeSelectTarget.classList.add("d-none")
    this.normalTypeSelectTarget.removeAttribute("name")
    this.guidedTourTypeSelectTarget.classList.remove(
      "d-none"
    )
    this.guidedTourTypeSelectTarget.setAttribute(
      "name",
      "shift[type]"
    )
  }
}
